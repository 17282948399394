<template>
  <div>
    <div class="text-center py-2" v-if="loading">
      <b-spinner variant="secondary" small/>
    </div>
    <template v-else>
      <pdf v-for="i in pages" :key="i" :src="file" :page="i" />
    </template>
  </div>
</template>

<script>
  import pdf from 'vue-pdf'

  export default {
    components: { pdf },
    props: {
      src: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      file: null,
      loading: true,
      pages: 0,
    }),
    mounted() {
      this.file = pdf.createLoadingTask(this.src)
      this.file.promise.then(({ numPages }) => {
        this.pages = numPages
        this.loading = false
      })
    },
  }
</script>
