<template>
  <div class="s-pdf-page">
    <nav>
      <p>{{ meta.fileName }}</p>
      <a class="btn btn" :href="`${meta.fileUrl}?openExternalBrowser=1`">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/></svg>
      </a>
    </nav>
    <PDFViewer :src="meta.fileUrl" />
  </div>
</template>

<script>
  import PDFViewer from '@/components/PDFViewer'
  import { decode } from '@/utils/Base64'

  export default {
    components: { PDFViewer },
    computed: {
      meta() {
        return decode(this.$route.params.meta)
      },
    },
  }
</script>

<style scoped>
  nav {
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #cdd6dc;
    height: 3rem;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
  }

  nav a {
    flex-shrink: 0;
    padding: 0.75rem 1rem;
  }

  nav a svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  p {
    margin: 0;
    margin-right: auto;
    padding-left: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .s-pdf-page {
    margin-top: 3rem;
  }
</style>
